<template>
    <HeroPage v-if="pageData" :data="pageData" />

    <VideoGridFilterCollection :paging="fetchDataParams.paging"
        :collectionFilter="['Categories', 'Level', 'Coaches & Trainers']" :searchParams="searchParams" />

    <PageLayoutBuilder v-if="pageData" :data="pageData" />
</template>

<script>
import HeroPage from "@/components/HeroPage";

import PageLayoutBuilder from "@/components/PageLayoutBuilder";

import VideoGridFilterCollection from "@/components/VideoGridFilterCollection";

import {
    fetchPageBySlug,
} from "@streampac.io/chef_sp_1";

export default {
    name: "VideoCatTacticalClasses",
    inject: ["isAuthenticated"],
    // metaInfo() {
    //     return {
    //         title:
    //             "Home | LEAD Hockey",
    //         description:
    //             "",
    //     };
    // },
    data() {
        return {
            pageData: null,
            fetchDataParams: {
                paging: {
                    pageNumber: 1,
                    pageSize: 6,
                },
            },
            searchParams: [
                {
                    type: "Video Type",
                    // //TEST
                    // value: "04d64957-d2a3-4273-b90d-e8fac6452e94",
                    //LIVE
                    value: "a86474fc-55b1-4dba-98c3-efce210617a1"
                },
            ],
        };
    },
    components: {
        HeroPage,
        PageLayoutBuilder,
        VideoGridFilterCollection
    },
    watch: {
        isAuthenticated() {
            this.pageData = null;
            setTimeout(() => {
                this.fetchPage();
            }, 50);
        },
    },
    created() {
        this.fetchPage();
    },
    methods: {
        fetchPage() {
            fetchPageBySlug(this.isAuthenticated, "videos-masterclasses").then(
                (response) => {
                    this.pageData = response;
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
